import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import left_quote from "../../medias/icones/quote-left.svg";
import right_quote from "../../medias/icones/quote-right.svg";

const AdviceCarousel = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                transitionDuration={750}
                containerClass="carousel-container min-h-250 md:min-h-350"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="bottom-"
                itemClass="carousel-item-padding-40-px"
            >
                <div className="center w-full px-6 md:px-0 md:w-11/12 lg:w-5/6 xl:w-2/3 mx-auto">
                    <div className="mb-96 pb-44 md:mb-12">
                        <img src={left_quote} alt="guillemets gauche" className="md:w-1/2 relative top-0 xl:w-1/3 mx-auto" />
                    </div>
                    <div className="text-main-white">
                        <p className="text-lg lg:text-xl">
                            J'avais un besoin rapide de mise en place d'un site afin d'accélérer la croissance de ma société, Julien a su répondre à mes attentes
                            tant en terme de délais que de compétences. Toujours pédagogue et prêt à utiliser ses compétences commerciales pour m'aider dans la réalisation
                            de ce projet. Je recommande vivement ce développeur passionné et efficace.
                        </p>
                        <p className="italic pt-4"><small>Arnaud Boucher - AB ramonage</small></p>
                    </div>
                    <div className="mb-96 pb-44 md:mb-12">
                        <img src={right_quote} alt="guillemets droite" className="md:w-1/2 relative top-0 xl:w-1/3 mx-auto" />
                    </div>
                </div>
                <div className="center w-full px-6 md:px-0 md:w-11/12 lg:w-5/6 xl:w-2/3 mx-auto">
                    <div className="mb-96 pb-44 md:mb-12">
                        <img src={left_quote} alt="guillemets gauche" className="md:w-1/2 relative top-0 xl:w-1/3 mx-auto" />
                    </div>
                    <div className="text-main-white">
                        <p className="text-lg lg:text-xl">
                            Lors d'une mission temporaire de 2 mois, j'ai eu l'occasion de travailler avec Julien pour le développement de ma plateforme en REACT.
                            Julien est une personne sérieuse et de bonne volonté. Force de proposition, il nous a vraiment bien aidé en complétant une par une les missions qui lui ont été confié.
                            Au délà de ca, Julien est une personne très serviable, sympatique et bienveillante.
                        </p>
                        <p className="italic pt-4"><small>Arnaud Méliande - NLFL</small></p>
                    </div>
                    <div className="mb-96 pb-44 md:mb-12">
                        <img src={right_quote} alt="guillemets droite" className="md:w-1/2 relative top-0 xl:w-1/3 mx-auto" />
                    </div>
                </div>
            </Carousel>
        </>
    );
};

export default AdviceCarousel;
