import React, {Component} from 'react';
import Header from "../../components/Header/Header";

import linkedin_icon from '../../medias/icones/linkedin.svg';
import malt_icon from '../../medias/icones/malt-logo.svg';
import twitter_icon from '../../medias/icones/twitter.svg';
import facebook_icon from '../../medias/icones/facebook.svg';
import ContactForm from "../../components/Forms/ContactForm";
import QuoteSection from "../../components/Sections/QuoteSection";

class ContactPage extends Component {

    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }
    render() {
        return (
            <>
                <Header />
                <section className="text-main-dark p-2 lg:p-0">
                    <h2 className="text-center text-main-blue py-4 md:py-6 lg:py-8 text-2xl md:text-5xl">Contact</h2>
                    <div className="responsive-center border-2 border-main-dark rounded-xl px-4 md:px-8 lg:px-12 py-6 md:py-12 lg:py-20 my-5 md:my-8 lg:my-12 w-full md:w-3/5 mx-auto">
                        <div className="w-full lg:w-1/2 text-left text-lg md:text-xl lg:text-2xl">
                            <ul>
                                <li><small>Adresse :</small> 267 rue Achille Andris</li>
                                <li><small>Ville :</small>Somain</li>
                                <li><small>Code postal :</small>59490</li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 text-left text-lg md:text-xl lg:text-2xl">
                            <ul>
                                <li><small>Email :</small><a className="underline md:no-underline" href="mailto:contact@e-kigaï-dev.fr">contact@e-kigaï-dev.fr</a></li>
                                <li><small>Tél :</small> 06.50.66.43.38</li>
                                <li><small>Siret :</small> 888 642 220 00012</li>
                            </ul>
                        </div>
                    </div>
                    <div className="center w-full py-6 md:py-12 md:w-2/3 lg:w-1/2 mx-auto">
                        <div className="w-1/4">
                            <a href="https://www.linkedin.com/in/julien-grade/" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/2 lg:w-1/3 mx-auto" src={linkedin_icon} alt="Lien vers profil linkedin" />
                            </a>
                        </div>
                        <div className="w-1/4">
                            <a href="https://www.facebook.com/ekigaiDev/" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/2 lg:w-1/3 mx-auto" src={facebook_icon} alt="Lien vers profil facebook" />
                            </a>
                        </div>
                        <div className="w-1/4">
                            <a href="https://www.malt.fr/profile/juliengrade" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/2 lg:w-1/3 mx-auto" src={malt_icon} alt="Lien vers profil malt" />
                            </a>
                        </div>
                        <div className="w-1/4">
                            <a href="https://twitter.com/GJulien8" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/2 lg:w-1/3 mx-auto" src={twitter_icon} alt="Lien vers profil twitter" />
                            </a>
                        </div>
                    </div>
                </section>
                <section className="pt-4 md:pt-8 lg:pt-12">
                    <ContactForm />
                </section>
                <QuoteSection />
            </>
        );
    }
}

export default ContactPage;