import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import{ init } from 'emailjs-com';
import * as emailjs from "emailjs-com";
import {MAIL_CONTACT_KEY, CONTACT_TEMPLATE_ID, MAIL_SERVICE_ID } from '../../config';
import {toast} from "react-toastify";

import contact_mail from '../../medias/images/contact-mail.svg';

init(MAIL_CONTACT_KEY);

const ContactForm = ({history}) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [redirect, setRedirect] = useState(false);

    if(redirect){
        return <Redirect to="/" push />
    }

    const isEmail = () =>{
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if(email.match(regex)){
            return true;
        }else{
            toast.error("Problème dans le format de votre email");
            return false;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(name && message && isEmail()){
            sendFeedback(CONTACT_TEMPLATE_ID, {
                name,
                email,
                message,
            });
        }else {
            toast.error("Tous les champs du formulaire sont obligatoires");
        }
    };

    const sendFeedback = (templateId, variables) => {

        emailjs
            .send(MAIL_SERVICE_ID, templateId, variables)
            .then((res) => {
                toast.info("Message envoyé")
                setName("");
                setEmail("");
                setMessage("");
                setRedirect(true);
            })
            .catch(
                (err) =>
                    toast.error("Une erreur est survenue lors de l'envoi"));
    };

    return (
        <>
            <div className="bg-main-blue py-8 md:py-12 lg:py-16">
                <div className="block md:flex md:justify-center md:items-center bg-white  w-11/12 rounded-xl  shadow-lg md:w-3/4 lg:w-2/3 mx-auto">
                    <div className="w-full p-8 lg:p-16 md:w-1/2">
                        <h2 className="text-main-blue text-xl md:text-2xl lg:text-3xl">Contactez nous...</h2>
                        <p className="text-main-dark py-4 md:text-lg lg:text-xl">
                            Si vous préférez vous pouvez nous envoyer un mail directement <a className="underline text-main-blue" href="mailto:contact@e-kigaï-dev.fr">ici</a>
                        </p>
                        <img className="w-full" src={contact_mail} alt="envoyer un email" />
                    </div>
                    <div className="w-full p-8 lg:p-16 md:w-1/2">
                        <form>
                            <div className="">
                                <input value={name} onChange={(e)=> setName(e.target.value)}  placeholder="Votre prénom..." className="w-full bg-gray-300 text-main-dark mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" name="firstName"  />
                            </div>
                            <div className="">
                                <input value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Votre email..." className="w-full bg-gray-300 text-main-dark mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" type="email" name="email"  />
                            </div>
                            <div className="">
                                <textarea value={message} onChange={(e)=> setMessage(e.target.value)} placeholder="Message..." className="w-full bg-gray-300 text-main-dark mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outlin" rows={8} cols={50} name="message">

                                </textarea>
                            </div>
                            <div>
                                <button onClick={handleSubmit} className="uppercase text-sm font-bold tracking-wide bg-main-blue border-2 border-white text-main-white hover:text-main-blue hover:bg-white hover:border-main-blue transition-all duration-1000 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline" type="submit">ENVOYER</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactForm;
