import React from 'react';
import {Link} from "react-router-dom";

import logo from '../../medias/logo/logo-svg.svg';

const Footer = () => {
    return (
        <>
            <footer className="text-main-white bg-main-grey body-font">
                <div
                    className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <Link to="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                            <img className="object-cover w-full" src={logo} alt="e-kigaï-dev entreprise logo" />
                        </Link>
                        <p className="mt-2 text-sm text-center text-main-black">Développement web - Création de site internet - Formations</p>
                    </div>
                    <div className="flex-grow flex justify-end md:pl-20 -mb-10 md:mt-0 mt-10 md:-text-xl lg:text-2xl md:text-right text-center">
                        <div className="md:w-1/2 w-full px-4">
                            <nav className="list-none mb-10">
                                <ul>
                                    <li>
                                        <Link to="/sites-vitrines" className="text-main-black hover:text-main-white">Sites vitrines</Link>
                                    </li>
                                    <li>
                                        <Link to="/sites-e-commerces" className="text-main-black hover:text-main-white">Sites e-commerces</Link>
                                    </li>
                                    <li>
                                        <Link to="/nos-services" className="text-main-black hover:text-main-white">Nos services</Link>
                                    </li>
                                    <li>
                                        <Link to="/notre-histoire" className="text-main-black hover:text-main-white">Notre histoire</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" className="text-main-black hover:text-main-white">Nous contacter</Link>
                                    </li>
                                    <li>
                                        <Link to="/mentions-legales" className="text-main-black hover:text-main-white underline">Mentions légales</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="bg-main-blue">
                    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p className="text-main-white text-sm text-center sm:text-left">© 2021 Tous droits réservés <span className="hidden md:inline">- </span><br className="md:hidden" />
                            <a href="https://e-kigaï-dev.fr" rel="noopener noreferrer"
                               className="text-main-white underline ml-1" target="_blank">@e-kigaï-dev</a>
                        </p>
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                            <a aria-label="lien vers la page facebook de l'entreprise e-kigaï-dev" href="https://www.facebook.com/ekigaiDev/" rel="noreferrer noopener" target="_blank" className="text-main-white">
                              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5"
                                   viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z">

                                </path>
                              </svg>
                            </a>
                            <a aria-label="lien vers le compte twitter de Julien Grade" href="https://twitter.com/GJulien8" rel="noreferrer noopener" target="_blank" className="ml-3 text-main-white">
                              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5"
                                   viewBox="0 0 24 24">
                                <path
                                    d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">

                                </path>
                              </svg>
                            </a>
                            <a aria-label="lien vers la page Linkedin de l'entreprise e-kigaï-dev" href="https://www.linkedin.com/company/e-kiga%C3%AF-dev" rel="noreferrer noopener" target="_blank" className="ml-3 text-main-white">
                              <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0"
                                   className="w-5 h-5" viewBox="0 0 24 24">
                                <path stroke="none"
                                      d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">

                                </path>
                                <circle cx="4" cy="4" r="2" stroke="none">

                                </circle>
                              </svg>
                            </a>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
