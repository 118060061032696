import React, {Component} from 'react';
import lottie from 'lottie-web';

import'./LoaderPage.css';

import animationData from'../../medias/animation/logo.json';


let animObj = null;

class LoaderPage extends Component {
    componentDidMount() {


        //call the loadAnimation to start the animation
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData // the path to the animation json
        });
    }
    handleStop = () => {
        animObj.stop();
    }
    handlePlay() {
        animObj.play();
    }



    render() {

        const isMobile = window.innerWidth <= 480;
        return (
            <>
                <section className="bg-main-grey h-screen flex justify-center">
                    <div className="w-2/3 center h-full mx-auto">
                        {isMobile ? <div style={{width: 200, margin: '0 auto'}} ref={ ref => this.animBox = ref}></div> : <div style={{width: 400, margin: '0 auto'}} ref={ ref => this.animBox = ref}></div> }
                    </div>
                </section>
            </>
        );
    }
}

export default LoaderPage;