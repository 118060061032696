import React from 'react';
import {Link} from "react-router-dom";

import desktop_background from '../../medias/backgrounds/background-computer.svg';
import mobile_background from '../../medias/backgrounds/background-header-mobile.svg';


const Hero = () => {
    return (
        <>
            {/* Hero Desktop */}
            <section
                className="hidden md:block md:min-h-450 lg:min-h-500 xl:min-h-650 3xl:min-h-1000 w-full"
                style={{
                backgroundImage: `url(${desktop_background})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}>
                    <div className="w-1/2 md:pt-8 md:hidden lg:block lg:pt-12 mx-auto">
                        <h1 className="text-main-dark text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-8 3xl:text-5xl">Développement web - Création de site internet - Formations</h1>
                    </div>
                    <div className="absolute md:top-28 md:right-8 lg:right-16 lg:mt-0 xl:mt-8 xl:right-32 3xl:right-56 2xl:-mt-8">
                        <Link className="hover:text-main-white animate-pulse-slow border-4 hover:border-main-white border-main-dark md:p-3 xl:p-4 2xl:p-6 rounded-full md:text-xl xl:text-2xl text-main-dark" to="/devis">Demander un devis</Link>
                    </div>
            </section>
            {/* Hero Responsive */}
            <section
                className="md:hidden min-h-500 w-full"
                style={{
                    backgroundImage: `url(${mobile_background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}>
                    <div className="flex justify-center pt-40">
                        <Link className="hover:text-main-white animate-pulse-slow border-4 p-3 hover:border-main-white border-main-dark rounded-full text-main-dark" to="/devis">Demander un devis</Link>
                    </div>
            </section>
        </>
    );
};

export default Hero;
