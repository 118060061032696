import React, {Component} from 'react';
import Header from "../../components/Header/Header";

class PolicyPage extends Component {

    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="md:container px-4 md:px-8 py-4 md:py-8 lg:py-12 mt-6 md:mt-12 text-left mx-auto text-main-dark">
                    <article>
                        <h4 className="text-xl md:text-2xl lg:text-3xl">1. Présentation du site</h4>
                        <p className="md:text-xl mt-8">En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs
                            du site https://e-kigaï-dev.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                        </p><br/>
                        <p>
                            <strong>Propritétaire :</strong>e-kigaî-dev &middot; Siret 88864222000014 - 267, rue Achille Andris - 59 490 Somain<br/>
                            <strong>Créateur : </strong><a target="_blank" rel="noopener noreferrer" href="https://juliengrade.fr" className="text-info underline">Julien Grade</a><br/>
                            <strong>Code NAF : </strong>6201Z<br/>
                            <strong>Responsable publication : </strong> Julien Grade &middot; contact@e-kigaï-dev.fr <br/>
                            Le responsable publication est une personne physique ou une personne morale.<br/>
                            <strong>Webmaster : </strong>Julien Grade &middot; contact@e-kigaï-dev.fr<br/>
                            <strong>Hébergeur : </strong>ionos.fr
                        </p>
                    </article>
                    <article className="mt-5 md:mt-8">
                        <h4 className="text-xl md:text-2xl lg:text-3xl">2. Conditions générales d'utilisation du site et des services proposés</h4>
                        <p className="md:text-xl mt-8">
                            L’utilisation du site <a className="underline" href="https://e-kigaï-dev.fr">https://e-kigaï-dev.fr</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation
                            sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <a className="underline" href="https://e-kigaï-dev.fr">https://e-kigaï-dev.fr</a> sont donc invités à les consulter de manière régulière.
                        </p><br/>
                        <p className="md:text-xl mt-8">
                            Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par e-kigaï-dev, qui s’efforcera alors de communiquer préalablement
                            aux utilisateurs les dates et heures de l’intervention.
                        </p><br/>
                        <p className="md:text-xl mt-8">
                            Le site <a className="underline" href="https://e-kigaï-dev.fr">https://e-kigaï-dev.fr</a>  est mis à jour régulièrement par Julien Grade. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité
                            à s’y référer le plus souvent possible afin d’en prendre connaissance.
                        </p>
                    </article>
                    <article className="mt-5 md:mt-8">
                        <h4 className="text-xl md:text-2xl lg:text-3xl">3. Responsabilité</h4>
                        <p className="md:text-xl mt-8">
                            e-kigaï-dev ne pourra être tenu responsable de tout dommage direct ou indirect résultant de l’utilisation du site par un client de toute information.
                            Le site peut renvoyer l’utilisateur vers d’autres sites par le biais de liens hypertextes. Cependant e-kigaï-dev ne pourrait être tenu responsable du contenu de
                            ces sites l’entière responsabilité revient aux titulaires desdits sites.
                        </p>
                    </article>
                    <article className="mt-5 md:mt-8">
                        <h4 className="text-xl md:text-2xl lg:text-3xl">4. Protection des données personnelles</h4>
                        <p className="md:text-xl mt-8">
                            Vous pouvez être amené à nous communiquer des informations personnelles via différents formulaires. Ces informations ne sont
                            destinées qu’aux services et sociétés du groupe e-kigaï-dev chargés de répondre à vos demandes. Ces informations sont conservées pendant
                            la durée nécessaire à leur traitement. Vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données
                            qui vous concernent (art.34 de la loi “Informatique et Libertés” du 6 janvier 1978). Vous pouvez exercer ce droit par courrier électronique
                            à l’adresse suivante : contact@e-kigaï-dev.fr ou par courrier postal adressé à : e-kigaï-dev – 267 rue Achille Andris 59 490 Somain | FRANCE
                        </p>
                    </article>
                    <article className="mt-5 md:mt-8">
                        <h4 className="text-xl md:text-2xl lg:text-3xl">5. Cookies</h4>
                        <p className="md:text-xl mt-8">
                            La navigation sur le site https://e-kigaï-dev est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur
                            sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation. En naviguant sur le site https://e-kigaï-dev.fr vous consentez à l'utilisation de ceux-ci.
                        </p>
                    </article>
                    <article className="mt-5 md:mt-8">
                        <h4 className="text-xl md:text-2xl lg:text-3xl">5. Protection industrielle et intellectuelle</h4>
                        <p className="md:text-xl mt-8">
                            L’ensemble de ce site, notamment les textes, les logos, les tableaux, les représentations iconographiques et photographiques, les documents téléchargeables ainsi que tous les éléments du code source, relève de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. La reproduction
                            ou la représentation totale ou partielle d’un de ces éléments, sur quel que support que ce soit, est donc formellement interdite sauf accord préalable écrit d' e-kigaï-dev.
                        </p>
                    </article>
                </section>
            </>
        );
    }
}

export default PolicyPage;