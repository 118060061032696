import React, {Component} from 'react';

import Header from "../../components/Header/Header";
import QuoteSection from "../../components/Sections/QuoteSection";

import service_presentation from '../../medias/images/Service-presentation.svg';
import vitrine_exemple from '../../medias/images/vitrine-exemple.svg';
import croissance from '../../medias/images/croissance.svg';
import application from '../../medias/images/application.svg';
import techno_background from '../../medias/backgrounds/background-techno.svg';
import front_background from '../../medias/backgrounds/background-front-techno.svg';
import back_background from '../../medias/backgrounds/background-techno-back.svg';
import malt_icon from '../../medias/icones/malt-logo.svg';
import cv_icon from '../../medias/icones/job-application.svg';
import linkedin_icon from '../../medias/icones/linkedin.svg';
import black_box from '../../medias/images/black-box.svg';
import beginner from '../../medias/images/beginner.svg';
import confirmed from '../../medias/images/formations.svg';
import symfony_icon from '../../medias/icones/symfony.svg';
import react_icon from '../../medias/icones/react.svg';
import laravel_icon from '../../medias/icones/laravel.svg';
import tailwind_icon from '../../medias/icones/tailwind.svg';
import bootstrap_icon from '../../medias/icones/bootstrap.svg';
import mysql_icon from '../../medias/icones/mysql.svg'
import phone from "../../medias/images/phone.svg";


class ServicePage extends Component {
    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }
    render() {
        const isMobile = window.innerWidth <= 768;

        return (
            <>
                <Header />
                <section className="responsive-center lg:container py-12 lg:py-24 lg:mx-auto text-main-dark">
                    <div className="w-1/2 md:w-1/3 lg:ml-6 mx-auto lg:w-1/3">
                        <img className="w-full rounded-xl" src={service_presentation} alt="cadre e-kigaï-dev" />
                    </div>
                    <div className="w-full mt-8 lg:mt-0 lg:px-6 xl:px-20 md:w-2/3 mx-auto lg:w-2/3">
                        <p className="text-xl lg:leading-10 px-2 xl:px-8 lg:text-2xl">
                            Chez e-kigaï Dev nous plaçons le client au centre de notre de démarche et mettons tout en oeuvre afin de comprendre ses besoins et y répondre de manière efficace.
                            Par philosophie aussi bien que par pragmatisme nous privilégions des technologies dîtes ‘open-source’ qui sont donc libres de droit et n’engendrent pas de coûts liés
                            à leurs utilisations. Nous travaillons en méthode Agile ce qui veut dire que nous pouvons adapter le projet au fil de son développement et en fonction des souhaits
                            de notre client. Enfin nous avons à coeur de faire preuve de pédagogie et transmettre au client toutes les connaissances dont il aura besoin afin d’utiliser son
                            nouvel outil
                        </p>
                    </div>
                </section>
                <section className="bg-main-blue text-main-white">
                    <div className="py-4 md:py-10">
                        <h2 className="text-center text-2xl md:text-5xl">Réalisation de sites internet</h2>
                    </div>
                    <div className="responsive-center lg:w-11/12 lg:mx-auto py-8 lg:py-0 xl:pb-20 md:py-14">
                        <div className="w-full lg:min-h-650 xl:min-h-350 lg:my-12 lg:w-1/3">
                            <img className="object-cover h-28 md:h-52 mx-auto" src={vitrine_exemple} alt="exemple de site vitrine" />
                            <h3 className="text-xl my-4 md:text-2xl mt-6 lg:text-3xl">Sites vitrines</h3>
                            <p className="text-lg leading-10 md:leading-10 lg:leading-10 md:text-xl lg:text-2xl px-8">
                                Conception de site vitrine avec formulaire de contact, lancement d'appel version mobile et mise en ligne du projet
                            </p>
                        </div>
                        <div className="w-full lg:min-h-650 xl:min-h-350 py-12 lg:py-0 lg:w-1/3">
                            <img className="object-cover h-28 md:h-52 mx-auto" src={croissance} alt="exemple de site e-commerce" />
                            <h3 className="text-xl my-4 md:text-2xl mt-6 lg:text-3xl">Sites e-commerces</h3>
                            <p className="text-lg leading-10 md:leading-10 lg:leading-10 md:text-xl lg:text-2xl px-8">
                                Conception de site e-commerce avec administration, paiements, emailing et mise en ligne du projet
                            </p>
                        </div>
                        <div className="w-full lg:min-h-650 xl:min-h-350 lg:w-1/3">
                            <img className="object-cover h-28 md:h-52 mx-auto" src={application} alt="exemple d'application web" />
                            <h3 className="text-xl my-4 md:text-2xl mt-6 lg:text-3xl">Application Web</h3>
                            <p className="text-lg leading-10 md:leading-10 lg:leading-10 md:text-xl lg:text-2xl px-8">
                                Conception de site type réseau entreprise, CRM, outil de gestion et mise en ligne du projet
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="py-4 bg-techno-color md:py-10">
                        <h2 className="text-center text-main-blue text-2xl md:text-5xl">Missions free-lance</h2>
                    </div>
                    <div className="block md:flex md:justify-center md:items-center py-8 md:py-12 lg:py-20">
                        <div className="w-full md:w-1/3">
                            <a href="https://www.malt.fr/profile/juliengrade" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/4 mx-auto" src={malt_icon} alt="profil Malt" />
                            </a>
                            <h3 className="text-xl text-main-dark my-4 md:text-2xl mt-6 lg:text-3xl">Profil Malt</h3>
                            <p className="text-lg md:text-xl px-8 lg:text-2xl text-main-dark">
                                Visitez mon profil free-lance sur Malt
                            </p>
                        </div>
                        <div className="w-full md:w-1/3">
                            <a href="https://juliengrade.fr" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/4 mx-auto" src={cv_icon} alt="site cv" />
                            </a>
                            <h3 className="text-xl text-main-dark my-4 md:text-2xl mt-6 lg:text-3xl">CV numérique</h3>
                            <p className="text-lg md:text-xl px-8 lg:text-2xl text-main-dark">
                                Visitez mon site cv numérique
                            </p>
                        </div>
                        <div className="w-full md:w-1/3">
                            <a href="https://www.linkedin.com/in/julien-grade/" rel="noreferrer noopener" target="_blank">
                                <img className="w-1/4 mx-auto" src={linkedin_icon} alt="profil Linkedin" />
                            </a>
                            <h3 className="text-xl text-main-dark my-4 md:text-2xl mt-6 lg:text-3xl">Profil Linkedin</h3>
                            <p className="text-lg md:text-xl px-8 lg:text-2xl text-main-dark">
                                Visitez mon profil sur Linkedin
                            </p>
                        </div>
                    </div>
                    <div
                        style={{
                            backgroundImage: `url(${front_background})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        className="p-4 md:p-8 lg:p-12">
                        <div className="w-full border-4 bg-techno-color border-main-blue rounded-xl mr-2 lg:w-1/2">
                            <div className="p-2 lg:p-12">
                                <h3 className="text-xl my-4 md:text-3xl mt-6 lg:text-4xl text-main-blue">Le front-end</h3>
                                <p className="text-main-dark text-xl md:text-2xl">
                                    Nos compétences en HTML et CSS nous permettent de répondre à toutes les exigences en terme de rendu visuel statique. Afin d’ajouter
                                    du dynamisme et une très grande fluidité au site nous privilégions React ou React-native dans le cas d’une application mobile.
                                    Cependant nous pouvons mettre en place d’autres solutions techniques afin d’assurer le rendu visuel du projet,
                                    Twig avec Symfony par exemple. Parce qu’aujourd’hui 87% des pages sont consultées sur un mobile nous adoptons le concept de mobile-first
                                </p>
                            </div>
                        </div>
                    </div>
                    {isMobile
                        ?
                            <div
                                className="bg-techno-color min-h-450"
                            >
                                <div className="center py-4">
                                    <div className="w-2/6">
                                        <img className="w-1/2 mx-auto" src={bootstrap_icon} alt="Bootstrap" />
                                    </div>
                                    <div className="w-2/6">
                                        <img className="w-1/2 mx-auto" src={tailwind_icon} alt="Tailwind" />
                                    </div>
                                    <div className="w-2/6">
                                        <img className="w-1/2 mx-auto" src={react_icon} alt="React" />
                                    </div>
                                </div>
                                <p className="text-main-dark w-full px-2">
                                    Parce que tous les besoins ne nécessitent pas les mêmes réponses, nous adapterons le choix des technologies selon les vôtres.
                                    Nous avons toujours fait le choix de l’open-source car nous croyons dans ses valeurs et cela permet une grande modularité.
                                    Vous bénéficierez d’un projet ancré dans la modernité et maintenable. De plus nos outils et une méthode agile permettent une adaptabilité
                                    permanente avec validation point par point en vous donnant accès au développement du projet
                                </p>
                                <div className="center py-4">
                                    <div className="w-2/6">
                                        <img className="w-1/2 mx-auto" src={mysql_icon} alt="MySQL" />
                                    </div>
                                    <div className="w-2/6">
                                        <img className="w-1/2 mx-auto" src={symfony_icon} alt="Symfony" />
                                    </div>
                                    <div className="w-2/6">
                                        <img className="w-1/2 mx-auto" src={laravel_icon} alt="Laravel" />
                                    </div>
                                </div>
                            </div>
                        :
                            <div
                                className=" md:min-h-450 lg:min-h-650 center"
                                style={{
                                    backgroundImage: `url(${techno_background})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                <p className="text-main-dark w-full xl:w-2/3 lg:leading-10 md:px-6 md:text-xl lg:text-2xl">
                                    Parce que tous les besoins ne nécessitent pas les mêmes réponses, nous adapterons le choix des technologies selon les vôtres.
                                    Nous avons toujours fait le choix de l’open-source car nous croyons dans ses valeurs et cela permet une grande modularité.
                                    Vous bénéficierez d’un projet ancré dans la modernité et maintenable. De plus nos outils et une méthode agile permettent une adaptabilité
                                    permanente avec validation point par point en vous donnant accès au développement du projet
                                </p>
                            </div>
                    }

                    <div
                        style={{
                            backgroundImage: `url(${back_background})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        className="p-4 md:p-8 lg:p-12 lg:flex lg:justify-end">
                        <div className="w-full border-4 bg-techno-color border-main-blue rounded-xl lg:w-1/2">
                            <div className="p-2 lg:p-12">
                                <h3 className="text-xl my-4 md:text-3xl mt-6 lg:text-4xl text-main-blue">Le back-end</h3>
                                <p className="text-main-dark text-xl md:text-2xl">
                                    Nos compétences en PHP nous permettent d’adopter plusieurs frameworks de back-end. Que ce soit des besoins en Symfony ou Laravel,
                                    nous pouvons y répondre. De plus nous avons également des notions back-end en javascript avec Node, express, mongo. En terme de gestion de base
                                    de données nous travaillons avec MySQL, Mongo, nous prenons également en charge la création d'api. Nous respectons
                                    Parce que la partie back-end représente le coeur de métier d'un client nous mettons tout en oeuvre afin de garantir la sécurité des données
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="py-4 md:py-10">
                        <h2 className="text-center text-main-blue text-2xl md:text-5xl">Formations web</h2>
                    </div>
                    <div className="responsive-center px-2 py-4 lg:py-8 md:px-12 lg:px-20">
                        <div className="w-full lg:w-1/3">
                            <img className="w-1/2 mx-auto lg:w-full" src={black_box} alt="Complexité de l'informatique" />
                        </div>
                        <div className="w-full lg:w-2/3 text-main-dark">
                            <h3 className="text-xl md:text-3xl">L'informatique et le concept de boîte noire</h3>
                            <p className="text-lg md:text-xl mt-5">
                                Le concept de boîte noire représente un objet dont on ne comprend pas le fonctionnement mais
                                qu'on utilise, le meilleur exemple est l'ordinateur. En effet le monde moderne se complexifie
                                et les boîtes noires voient leur nombre augmenter de façons exponentielle. Qui aujourd'hui est
                                capable d'expliquer le fonctionnement de tous les objets qu'il utilise ?<br/>
                                C'est pourquoi nous nous sommes tout naturellement tourné vers la formation et ainsi contribuer
                                à faciliter la compréhension du web et des technologies qui y sont associées
                            </p>
                        </div>
                    </div>
                    <div className="bg-main-blue text-main-white">
                        <div className="responsive-center md:container md:mx-auto w-full">
                            <div className="w-full lg:w-1/4">
                                <img className="w-1/2 mx-auto lg:w-full" src={beginner} alt="formations niveau débutant" />
                            </div>
                            <div className="w-full lg:w-2/4">
                                <h3 className="text-xl font-bold py-2 md:py-4 md:text-2xl">Formations Débutants</h3>
                                <p className="text-lg px-2 py-2 md:py-4 md:text-xl">
                                    Parcequ'il faut bien débuter quelques part, nous donnons des formations web à un public novice
                                    en programmation. Comprendre comment fonctionne le web, être capable de rédiger le contenu d'une page
                                    web avec le langage HTML et la mettre en forme avec le CSS. Comprendre l'utilité des différents langages,
                                    quels outils utiliser et les grands concepts du web tels que le seo ou la conformité RGPD
                                </p>
                            </div>
                        </div>
                        <hr className="block my-6 mx-auto bg-main-white h-1 w-1/2" />
                        <div className="grid lg:flex lg:justify-center lg:items-center md:container pb-4 lg:pb-6 xl:pb-20 md:mx-auto w-full">
                            <div className="w-full lg:w-2/4">
                                <h3 className="text-xl font-bold py-2 md:py-4 md:text-2xl">Formations Avancées</h3>
                                <p className="text-lg px-2 md:text-xl">
                                    Le monde du web évolue très vite et les langages ainsi que les frameworks suivent le même rythme.
                                    Nous proposons des solutions de formation sur les frameworks front-end HTML CSS tels que Bootstrap ou Tailwind.
                                    Mais aussi sur les framework Javascript React ou les frameworks back-end avec Symfony ou Laravel. Nous proposons
                                    également des formations sur les langages PHP et Javascript mais aussi sur des thèmes plus spécifiques, prenez contact avec nous pour
                                    en savoir plus
                                </p>
                            </div>
                            <div className="w-full lg:w-1/4 order-first lg:order-last">
                                <img className="w-1/2 mx-auto lg:w-full" src={confirmed} alt="formations niveau confirmé" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-main-white text-main-dark">
                    <div className="w-full lg:11/12 xl:w-3/4 md:mx-auto p-2 lg:p-12">
                        <div className="responsive-center mt-8">
                            <div className="w-1/3 mx-auto">
                                <img className="w-full object-cover" src={phone} alt="appel téléphonique" />
                            </div>
                            <div className="w-full mt-8 md:w-2/3 px-2 lg:px-8 md:mx-auto">
                                <h2 className="text-center text-2xl md:text-5xl">On en parle ?</h2>
                                <p className="text-xl mt-5 md:text-2xl leading-10 md:leading-10">
                                    Nous vous proposons un rendez-vous de 30 mn par téléphone. Bénéficiez d’un regard neuf sur votre projet,
                                    d'un avis consultatif afin de connaître ses points forts et ses points d’amélioration, évoquer une éventuelle
                                    collaboration ou même répondre à une interrogation.
                                    Pour faire simple, il suffit de prendre un rendez-vous téléphonique en quelques clics.
                                </p>
                                <div className="py-8 w-full md:w-1/2 lg:w-2/3 mx-auto">
                                    <a href="https://calendly.com/e-kigai-dev/expertise-e-kigai-dev" rel="noreferrer noopener" target="_blank" className="ring-4 py-3 rounded-full shadow flex items-center justify-center ring-main-white border-2 border-white hover:border-main-blue bg-main-blue text-main-white text-lg lg:text-2xl font-bold transition-all duration-1000 hover:bg-main-white hover:text-main-blue md:py-4 md:text-lg md:px-4 xl:px-8">PRENDRE RENDEZ-VOUS</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <QuoteSection />
            </>
        );
    }
}

export default ServicePage;