import React, {Component} from 'react';

class NotFoundPage extends Component {
    render() {
        return (
            <>
                <h1 className="text-5xl">Vous êtes perdu...</h1>
            </>
        );
    }
}

export default NotFoundPage;