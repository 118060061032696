import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import abramonage_project from '../../medias/logo/logo-ab-ramonage.svg';
import friterie_project from '../../medias/logo/logo-friterie.svg';
import cv_project from '../../medias/logo/cv.png';
import pizza_project from '../../medias/logo/logo-pizza.svg';
import dog_project from '../../medias/logo/shadow.svg';

const VitrineCarousel = (props) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                transitionDuration={750}
                containerClass="carousel-container min-h-250 md:min-h-350"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="bottom-"
                itemClass="carousel-item-padding-40-px"
            >
                <div className="center">
                    <a href="https://abramonage.com" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site pour artisan</p>
                        <img className="h-32 mx-auto" src={abramonage_project} alt="entreprise ab-ramonage" />
                    </a>
                </div>
                <div className="center">
                    <a href="https://v1-friterie.e-kigaï-dev.fr" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site pour restaurant</p>
                        <img className="h-32 mx-auto" src={friterie_project} alt="entreprise restauration type friterie" />
                    </a>
                </div>
                <div className="center">
                    <a href="https://juliengrade.fr" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site cv numérique</p>
                        <img className="h-32 mx-auto" src={cv_project} alt="site de cv numérique" />
                    </a>
                </div>
                <div className="center">
                    <a href="https://v1-pizza.e-kigaï-dev.fr" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site pour restaurant</p>
                        <img className="h-32 mx-auto" src={pizza_project} alt="entreprise restauration type pizza" />
                    </a>
                </div>
                <div className="center">
                    <a href="https://shadow.juliengrade.fr" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site pour particulier et leur chien</p>
                        <img className="h-32 mx-auto" src={dog_project} alt="site pour chien de la maison" />
                    </a>
                </div>
            </Carousel>
        </>
    );
};

export default VitrineCarousel;
