import React from 'react';
import {Link} from "react-router-dom";

import quotation from "../../medias/images/quotation.svg";

const QuoteSection = () => {
    return (
        <>
            <section className="bg-main-blue text-main-white">
                <div className="w-full xl:w-3/4 md:mx-auto p-2 md:p-12">
                    <div className="grid xl:flex xl:justify-center xl:items-center mt-8">
                        <div className="w-full mt-8 px-2 md:px-8 md:mx-auto order-last xl:order-first ">
                            <h2 className="text-center text-2xl md:text-5xl">Demander un devis</h2>
                            <p className="text-xl mt-5 md:text-2xl leading-10 md:leading-10">
                                Que vous ayez un projet abouti ou qu’il n’en soit encore qu’aux balbutiements, nous pouvons vous apporter des réponses.
                                Nous serons force de proposition sur des sujets variés tels que le design, le choix des technologies, la réalisation, le
                                déploiement et le référencement de votre projet. Nous vous proposons de nous fournir quelques informations ainsi nous
                                vous recontacterons dans les plus brefs délais afin de finaliser votre devis
                            </p>
                            <div className="py-8 w-full md:w-1/2 mx-auto">
                                <Link to="/devis" className="ring-4 py-3 rounded-full shadow flex items-center justify-center ring-main-white bg-main-blue text-main-white text-lg lg:text-2xl font-bold transition-all duration-1000 hover:bg-main-white hover:text-main-blue md:py-4 md:text-lg md:px-4 xl:px-8">DEMANDER UN DEVIS</Link>
                            </div>
                        </div>
                        <div className="w-1/3 mx-auto order-first xl:order-last">
                            <img className="w-full object-cover" src={quotation} alt="demande de devis" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default QuoteSection;
