import React, {Component} from 'react';
import {Link} from "react-router-dom";

import Header from "../../components/Header/Header";

import logo from '../../medias/logo/logo-svg.svg';
import key from '../../medias/images/key.svg';
import who from '../../medias/images/who.svg';
import phone from '../../medias/images/phone.svg';
import about_icon from '../../medias/icones/About-icon.svg';
import techno_icon from '../../medias/icones/Techno-icon-circle.svg';
import service_icon from '../../medias/icones/Service-icon.svg';
import QuoteSection from "../../components/Sections/QuoteSection";

class HomePage extends Component {
    render() {
        return (
            <>
                <Header />
                <section className="responsive-center my-8 md:w-3/5 md:mx-auto">
                    <div className="flex justify-center items-center lg:w-1/3">
                        <img className="object-cover w-1/2 mx-auto lg:w-full" src={logo} alt="logo e-kigaï-dev"/>
                    </div>
                    <div className="p-4 lg:p-16 my-auto text-main-dark lg:w-2/3">
                        <h1 className="text-2xl md:text-4xl mb-5">e-kigaï-dev<br/>Développement web</h1>
                        <p className="text-xl md:text-2xl md:leading-10">
                            Agence de développement web, spécialisée dans la conception et la réalisation de sites et d’applications internet et internet mobile.
                            Notre ambition est d’apporter à nos clients un réel outil personnalisé au quotidien.
                            À nos yeux l’écoute, la compréhension et l’accompagnement tout au long de votre démarche seront les clés du succès de votre projet
                        </p>
                    </div>
                </section>
                <section className="bg-main-blue text-main-white">
                    <div className="py-12">
                        <div className="py-4 md:py-10">
                            <h2 className="text-center text-2xl md:text-5xl">Prise en charge et accompagnement projet</h2>
                        </div>
                        <div className="responsive-center px-4 pb-10 md:px-12">
                            <div className="w-full lg:w-1/3">
                                <h3 className="text-2xl md:text-4xl px-4 py-4 md:py-8 font-bold">Un projet web c'est quoi ?</h3>
                                <p className="text-xl leading-9 md:leading-10 md:text-2xl">
                                    Un projet web englobe tout un panel de compétences, en effet cela fait appel à des compétences de graphisme, de design, de rédaction de contenu, d'hébergement sur un serveur,
                                    de mise en conformité légale (RGPD), de seo (trouver son site sur les moteurs de recherche). Bien souvent on se retrouve devant
                                    un tas de nouvelles notions qui peuvent parâitre abstraites et pourtant tellement synonymes de succès
                                </p>
                            </div>
                            <div className="w-2/3 mx-auto lg:w-1/3">
                                <img src={key} alt="Accompagnement web" />
                            </div>
                            <div className="w-full lg:w-1/3">
                                <h3 className="text-2xl md:text-4xl py-8 font-bold">Notre réponse</h3>
                                <p className="text-xl leading-9 md:leading-10 md:text-2xl">
                                    Nous avons l'ensemble des compétences nécessaires à la réalisation de votre projet ainsi que tous les outils.
                                    En effet nous bénéficions d'une expertise commerciale acquise durant 15 ans, ce qui nous permet
                                    aujourd'hui d'accompagner nos clients et les aider dans la réussite commerciale de leur entreprise. De plus nous mettons un point d'honneur
                                    à vulgariser au maximum notre discours et faisons preuve de pédagogie afin que notre client acquièrt de nouvelles connaissances
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="block xl:flex xl:justify-center xl:items-center text-main-dark py-12 md:px-12">
                    <div className="w-full xl:w-1/3">
                        <h2 className="text-center text-2xl md:text-5xl lg:text-3xl xl:text-5xl">Á qui s'adressent nos services ?</h2>
                    </div>
                    <div className="w-2/3 lg:w-1/3 mx-auto xl:w-1/3">
                        <img className="object-cover w-full" src={who} alt="qui ?" />
                    </div>
                    <div className="w-full lg:w-5/6 xl:w-1/3 mx-auto">
                        <p className="text-xl md:text-2xl px-2">
                            Que vous soyez un professionnel ou un particulier, une tpe ou une pme, un artisan ou un commerçant. Que vous
                            proposiez des services ou des produits ou que ce soit dans une démarche associative. Que vous ayez un projet abouti, juste une idée
                            ou que vous exprimiez un besoin. Nous saurons vous apporter une solution en corrélation avec votre budget. N'hésitez pas, contactez nous
                        </p>
                    </div>
                </section>
                <section>
                    <div className="responsive-center py-6 md:py-10 lg:py-16 w-full px-4 xs:px-6 lg:w-11/12 mx-auto">
                        <div className="w-full card-zoom rounded-xl shadow-lg lg:w-1/3 lg:mx-auto lg:mx-5 group">
                            <div className="card-zoom-image bg-commerce">
                                <h2 className="text-main-white py-4 lg:py-2 xl:py-10 text-xl xl:text-3xl">Sites e-commerces</h2>
                                <div className="opacity-0 group-hover:opacity-100 transition-all duration-1000">
                                    <div>
                                        <p className="px-6 lg:px-4 xl:px-6 text-main-white text-xs">
                                            Offrez vous de la visibilité sur internet, une boutique ouverte sans interruption que vous administrez vous-même très facilement et qui vous
                                            permettra d'accroître votre CA
                                        </p>
                                    </div>
                                    <div className="mt-3 xs:mt-6 md:mt-3 lg:mt-1 xl:mt-12 mr-8 lg:mr-4 xl:mr-8 flex justify-end">
                                        <Link className="py-2 px-4 xs:py-4 xs:px-8 lg:py-2 lg:px-4 xl:py-4 xl:px-8 rounded-full border-2 border-main-white hover:bg-main-white hover:text-main-dark text-main-white xs:text-lg md:text-xl lg:text-sm" to="/sites-e-commerces">DÉCOUVRIR</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full my-4 lg:my-0 card-zoom rounded-xl shadow-lg lg:w-1/3 lg:mx-auto lg:mx-5 group">
                            <div className="card-zoom-image bg-vitrine">
                                <h2 className="text-main-dark py-4 lg:py-2 xl:py-10 text-xl xl:text-3xl">Sites vitrines</h2>
                                <div className="opacity-0 group-hover:opacity-100 transition-all duration-1000">
                                    <div>
                                        <p className="px-6 lg:px-4 xl:px-6 text-main-dark text-xs">
                                            Offrez vous de la visibilité sur internet, un nouveau moyen de communication. Ce type de site s'adresse à toutes les bourses, nous saurons trouver
                                            une solution en adéquation avec votre budget
                                        </p>
                                    </div>
                                    <div className="mt-6 md:mt-3 lg:mt-4 xl:mt-12 mr-8 lg:mr-4 xl:mr-8 flex justify-end">
                                        <Link className="py-4 px-8 lg:py-2 lg:px-4 xl:py-4 xl:px-8 rounded-full border-2 border-main-dark hover:bg-main-dark hover:text-main-white text-main-dark text-lg md:text-xl lg:text-sm" to="/sites-vitrines">DÉCOUVRIR</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full card-zoom rounded-xl shadow-lg lg:w-1/3 lg:mx-auto lg:mx-5 group bg-opacity-100 group-hover:bg-opacity-30">
                            <div className="card-zoom-image bg-service">
                                <h2 className="text-main-white py-4 lg:py-2 xl:py-10 text-xl xl:text-3xl">Prestations web</h2>
                                <div className="opacity-0 group-hover:opacity-100 transition-all duration-1000">
                                    <div>
                                        <p className="px-6 lg:px-4 xl:px-6 text-main-white text-xs">
                                            Que vous ayez un besoin en formation, un besoin d'optimisation de votre site, une besoin spécifique sur un projet ou tout simplement
                                            une mission free-lance, nous pourrons répondre à ce besoin.
                                        </p>
                                    </div>
                                    <div className="mt-6 md:mt-3 lg:mt-4 xl:mt-12 mr-8 lg:mr-4 xl:mr-8 flex justify-end">
                                        <Link className="py-4 px-8 lg:py-2 lg:px-4 xl:py-4 xl:px-8 rounded-full border-2 border-main-white hover:bg-main-white hover:text-main-dark text-main-white text-lg md:text-xl lg:text-sm" to="/nos-services">DÉCOUVRIR</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-main-blue text-main-white">
                    <div className="w-full md:w-6/7 xl:w-3/4 md:mx-auto p-2 md:p-12">
                        <div className="responsive-center mt-8">
                            <div className="w-1/3 mx-auto">
                                <img className="w-full object-cover" src={phone} alt="appel téléphonique" />
                            </div>
                            <div className="w-full mt-8 lg:w-2/3 px-2 md:px-8 md:mx-auto">
                                <h2 className="text-center text-2xl md:text-5xl">On en parle ?</h2>
                                <p className="text-xl mt-5 md:text-2xl leading-10 md:leading-10">
                                    Nous vous proposons un rendez-vous de 30 mn par téléphone. Bénéficiez d’un regard neuf sur votre projet,
                                    d'un avis consultatif afin de connaître ses points forts et ses points d’amélioration, évoquer une éventuelle
                                    collaboration ou même répondre à une interrogation.
                                    Pour faire simple, il suffit de prendre un rendez-vous téléphonique en quelques clics.
                                </p>
                                <div className="py-8 w-full md:w-1/2 mx-auto">
                                    <a href="https://calendly.com/e-kigai-dev/expertise-e-kigai-dev" rel="noreferrer noopener" target="_blank" className="ring-4 py-3 rounded-full shadow flex items-center border-2 border-main-blue hover:border-main-white justify-center ring-main-white bg-main-blue text-main-white text-lg lg:text-2xl font-bold transition-all duration-1000 hover:bg-main-white hover:text-main-blue md:py-4 md:text-lg md:px-4 xl:px-8">PRENDRE RENDEZ-VOUS</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="responsive-center">
                    <div className="responsive-center my-8 w-full md:w-3/5 lg:w-9/12 xl:w-3/5 mx-auto">
                        <div className="w-full xl:w-1/3 mx-auto min-h-350 px-6">
                            <Link to="/nos-services">
                                <img className="object-cover w-1/2 mx-auto" src={service_icon} alt="les services de l'entreprise" />
                            </Link>
                            <h3 className="text-main-blue text-xl my-4 md:text-2xl lg:text-3xl">Nos services</h3>
                            <p className="text-main-dark">
                                Conception de site vitrine, site e-commerce, outils d’administration, CRM , réalisation en front-end et en back-end, formations web
                            </p>
                        </div>
                        <div className="w-full xl:w-1/3 px-6 min-h-350">
                            <Link to="/nos-services">
                                <img className="object-cover w-1/2 mx-auto" src={techno_icon} alt="les technologies utilisées par l'entreprise" />
                            </Link>
                            <h3 className="text-main-blue text-xl my-4 md:text-2xl lg:text-3xl">Technologies</h3>
                            <p className="text-main-dark">
                                Utilisation de technologies récentes et gratuites (React, NodeJs, Création d’Api, Symfony). On vous livre un site tourné vers l’avenir
                            </p>
                        </div>
                        <div className="w-full xl:w-1/3 px-6 min-h-350">
                            <Link to="notre-histoire">
                                <img className="object-cover w-1/2 mx-auto" src={about_icon} alt="l'histoire de l'entreprise" />
                            </Link>
                            <h3 className="text-main-blue text-xl my-4 md:text-2xl lg:text-3xl">Notre histoire</h3>
                            <p className="text-main-dark">
                                Si vous souhaitez en savoir plus sur nous. Découvrir qui nous sommes, où nous opérons et notre philosophie
                            </p>
                        </div>
                    </div>
                </section>
                <QuoteSection />
            </>
        );
    }
}

export default HomePage;