import React from 'react';
import {Link, NavLink} from "react-router-dom";
import { Transition, Menu } from '@headlessui/react'

import logo from '../../medias/logo/logo-svg.svg';
import close from '../../medias/icones/close.svg';
import menu from '../../medias/icones/menu.svg';

const Navbar = () => {

    return (
        <>
            {/* Desktop Navbar */}
            <nav className="hidden bg-main-grey min-h-100 xl:min-h-150 w-full my-auto mx-auto lg:flex items-center justify-center">
                <div className="absolute inline left-0">
                    <NavLink as={Link} to="/">
                        <img className="ml-4 object-center w-auto h-16 xl:h-20 cursor-pointer" src={logo} alt="e-kigaÏ-dev, logo du site" />
                    </NavLink>
                </div>
                <ul className="flex w-full ml-36 xl:ml-48 mr-4 justify-between space-x-2 xl:space-x-4 ">
                    <li className="w-1/5">
                        <NavLink className="navigation-button w-full" as={Link} to="/sites-vitrines">
                            Sites vitrines
                        </NavLink>
                    </li>
                    <li className="w-1/5">
                        <NavLink className="navigation-button w-full" as={Link} to="/sites-e-commerces">
                            Sites e-commerces
                        </NavLink>
                    </li>
                    <li className="w-1/5">
                        <NavLink className="navigation-button" as={Link} to="/nos-services">
                            Nos services
                        </NavLink>
                    </li>
                    <li className="w-1/5">
                        <NavLink className="navigation-button" as={Link} to="/notre-histoire">
                            Notre histoire
                        </NavLink>
                    </li>
                    <li className="w-1/5">
                        <NavLink className="navigation-button" as={Link} to="/contact">
                            Nous contacter
                        </NavLink>
                    </li>
                </ul>
            </nav>
            {/* Responsive Navbar */}
            <div className="flex justify-center">
                <nav className="fixed top-2 w-11/12 z-50  shadow-md ring-1 ring-gray-900 ring-opacity-5 p-1 lg:hidden rounded-lg bg-white">
                    <Menu>
                        {({ open }) => (
                            <>
                                <div className="px-2 h-16 flex items-center justify-between">
                                    <Link to="/" ><img alt="e-kigaÏ-dev, logo du site" className="h-16 w-16 sm:h-10" src={logo}/></Link>
                                    <h1 className="text-xs px-1 leading-tight text-main-blue">Développement web <br/> Création de site internet <br/> Formations</h1>
                                    <div className="-mr-2">

                                        <Menu.Button className="icon-toggle bg-white rounded-md p-2 inline-flex items-center justify-center ring-1 ring-gray-900 ring-opacity-20">
                                            {open
                                                ?
                                                <img className="h-6 w-6" alt="fermer le menu" src={close} />
                                                :
                                                <img className="h-6 w-6" alt="sortir menu" src={menu} />
                                            }
                                        </Menu.Button>
                                    </div>
                                </div>
                                <Transition
                                    show={open}
                                    enter="transition duration-1000 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-400 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items>
                                        <ul className="toggle-menu p-2">
                                            <Menu.Item>
                                                <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-400 hover:bg-gray-50" >Accueil</Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link  to="/sites-vitrines" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-400 hover:bg-gray-50" >Sites vitrines</Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to="/sites-e-commerces" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-400 hover:bg-gray-50" >Sites e-commerces</Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to="/nos-services" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-400 hover:bg-gray-50" >Nos services</Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to="/notre-histoire" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-400 hover:bg-gray-50" >Notre histoire</Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to="/contact" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-400 hover:bg-gray-50" >Nous contacter</Link>
                                            </Menu.Item>
                                        </ul>
                                    </Menu.Items>

                                </Transition>
                            </>
                        )}


                    </Menu>
                </nav>
            </div>
        </>
    );
};

export default Navbar;
