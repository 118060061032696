import React, {Component} from 'react';

import Header from "../../components/Header/Header";

import commerce from "../../medias/images/commerce.svg";
import shopping from "../../medias/images/shopping.svg";
import restaurant from "../../medias/images/restaurant.svg";
import services from "../../medias/images/services.svg";
import EcommerceCarousel from "../../components/Carousel/EcommerceCarousel";
import QuoteSection from "../../components/Sections/QuoteSection";

class EcommercePage extends Component {

    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="text-main-dark p-4 lg:p-12">
                    <div className="py-4 lg:py-8">
                        <h2 className="text-center text-2xl md:text-5xl">Réalisation de sites e-commerces</h2>
                    </div>
                    <div className="block xl:flex xl:justify-center xl:items-center py-4 lg:py-8">
                        <div className="w-full lg:h-500 xl:w-2/5">
                            <h3 className="text-main-blue text-2xl md:text-4xl px-4 py-4 md:py-8 font-bold">Un site e-commerce, c'est quoi ?</h3>
                            <p className="text-lg md:text-xl text-center xl:text-right md:leading-10 leading-10">
                                C'est un site web et web mobile possédant les atouts d’un site vitrine mais qui permet de réaliser des transactions, commandes,
                                réservations ou achats en ligne. Il est le reflet de votre activité et permet de proposer en ligne tous vos services et produits,
                                il comporte un espace client, qui lui permettra de commander, récupérer ses factures et à vous de les fidéliser. Un site e-commerce
                                bien réalisé peut nettement augmenter le chiffre d’affaire et maintenir votre activité en continu. Il permet d'étendre sa zone de
                                chalandise en s'affranchissant des contraintes géographiques et ainsi toucher de nouveaux clients
                            </p>
                        </div>
                        <div className="w-full xl:w-1/5">
                            <img className="object-cover w-full" src={commerce} alt="sites e-commerces" />
                        </div>
                        <div className="w-full lg:h-500 xl:w-2/5">
                            <h3 className="text-main-blue text-2xl md:text-4xl px-4 py-4 md:py-8 font-bold">Les plus de nos sites e-commerces</h3>
                            <p className="text-lg md:text-xl text-center xl:text-left leading-10 md:leading-10">
                                Notre solution vous permet de garder la main sur le contenu de votre site, ainsi vous pouvez controler toutes les parties de votre site,
                                que ce soit l'ajout de produits ou services, les publications, la création de promotions. Notre expertise en création d'espace administrateur
                                vous garantit d'avoir un nouvel outil de travail au delà d'une croissance de votre chiffre d'affaire. De plus nos compétences commerciales
                                sont mobilisées afin de vous aider à faire de cette boutique en ligne une réussite conforme à votre image et la qualité de vos services ou
                                produits, mais également un outil de gestion de relation client
                            </p>
                        </div>
                    </div>
                </section>
                <section className="bg-main-blue text-main-white">
                    <div className="p-4 lg:p-12">
                        <div className="py-4 lg:py-8">
                            <h2 className="text-center text-2xl md:text-5xl">Un site e-commerce, c'est pour qui ?</h2>
                        </div>
                        <div className="responsive-center">
                            <div className="w-full lg:w-1/3 mt-5 lg:min-h-650 lg:mt-0">
                                <img className="w-1/2 lg:h-32 xl:h-60 mx-auto" src={shopping} alt="commerce" />
                                <h3 className="text-xl py-4 md:text-2xl">Commercants</h3>
                                <p className="px-6 text-lg">
                                    Un site e-commerce permet à un commerçant de vendre ses produits en ligne et de la gérer comme une boutique ou agence physique. C'est aussi un moyen
                                    de fidéliser ses clients en étant proche d'eux, de plus votre boutique est accessible sans interruption et peut prendre le relais de votre boutique
                                    physique. C'est une source de revenu complémentaire
                                </p>
                            </div>
                            <div className="w-full lg:w-1/3 mt-5 lg:min-h-650 lg:mt-0">
                                <img className="w-1/2 lg:h-32 xl:h-60 mx-auto" src={restaurant} alt="restaurateur" />
                                <h3 className="text-xl py-4 md:text-2xl">Restaurateurs</h3>
                                <p className="px-6 text-lg">
                                    Un site e-commerce permet à un restaurateur de réaliser des commandes en click & collect, voir même en livraison. Il permet de mettre en avant
                                    sa carte et de la modifier comme on le souhaite, mais aussi de pouvoir gérer ses commandes et leurs statuts. Le site permet également de réaliser
                                    des paiements et de notifier son client du statut de sa commande.
                                </p>
                            </div>
                            <div className="w-full lg:w-1/3 mt-5 lg:min-h-650 lg:mt-0">
                                <img className="w-1/2 lg:h-32 xl:h-60 mx-auto " src={services} alt="vente de services" />
                                <h3 className="text-xl py-4 md:text-2xl">Vente de services</h3>
                                <p className="px-6 text-lg">
                                    Un site e-commerce permet à un prestataire de service ou un artisan de vendre ses services, de réaliser des devis, de mettre en ligne des produits
                                    ou services afin de communiquer dessus. Il permet également de générer des contacts, de véhiculer son image de marque et ses valeurs mais aussi
                                    de réaliser des reservations, commandes et en assurer les paiements
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="p-12">
                        <div className="py-4 lg:py-8">
                            <h2 className="text-center text-main-blue text-2xl md:text-5xl">Nos réalisations</h2>
                        </div>
                        <EcommerceCarousel />
                    </div>
                </section>
                <QuoteSection />
            </>
        );
    }
}

export default EcommercePage;