import React, {Component} from 'react';
import Header from "../../components/Header/Header";

import estimate from '../../medias/images/estimate.svg';
import {Link} from "react-router-dom";
import QuoteForm from "../../components/Forms/QuoteForm";

class QuotePage extends Component {
    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }
    render() {
        return (
            <>
                <Header />
                <section className="py-4 md:py-8 lg:py-12">
                    <div className="md:container mx-auto">
                        <h2 className="text-center text-main-blue py-4 md:py-6 lg:py-8 text-2xl md:text-5xl">Demandez un devis</h2>
                        <div className="block md:flex md:justify-center md:items-center">
                            <div className="w-full md:w-1/4 lg:w-1/3">
                                <img className="w-full" src={estimate} alt="demande de devis" />
                            </div>
                            <div className="w-full md:w-3/4 lg:w-2/3 px-2">
                                <p className="text-main-dark text-lg md:text-xl lg:text-2xl">
                                    Que vous ayez une idée aboutie ou non de votre projet, que ce soit pour une demande de formation, vous pouvez compléter le formulaire ci-dessous.
                                    Nous prendrons ensuite rapidement contact avec vous afin d’analyser au mieux vos besoins et réaliser votre devis. Vous pouvez également nous contacter
                                    directement <Link to="/contact" className="underline text-main-blue">ici</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-main-blue py-4 md:py-8 lg:py-12">
                    <QuoteForm />
                </section>
            </>
        );
    }
}

export default QuotePage;