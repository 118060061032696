import React, {Component} from 'react';

import AdviceCarousel from "../../components/Carousel/AdviceCarousel";
import Header from "../../components/Header/Header";

import opaque_logo from '../../medias/logo/background-logo.svg';
import ikigai_background from '../../medias/backgrounds/background-ikigai-dev.svg';
import definition from '../../medias/images/def-ikigai.svg';
import philo_background from '../../medias/backgrounds/ikigai-background-philo.svg';

class AboutPage extends Component {

    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="">
                    <div className="responsive-center py-12 lg:min-h-450 md:container md:mx-auto px-4 md:px-0">
                        <div className="w-full md:w-1/2 mx-auto pb-6 lg:ml-4 xl:ml-0 lg:py-0 lg:w-1/3">
                            <img className="w-full" src={opaque_logo} alt="e-kigaï-dev" />
                        </div>
                        <div className="w-full lg:w-2/3">
                            <p className="text-main-dark md:px-6 text-xl lg:px-8 md:text-2xl">
                                Fondé en 2020 par <br className="md:hidden" /> <a className="underline text-main-blue" href="https://juliengrade.Fr" rel="noreferrer noopener" target="_blank">Julien Grade</a>, développeur web, e-kigaï-dev est une structure ayant pour but de démocratiser
                                le web et donner ainsi accès à tous au développement d’un site web. De nos jours il devient impératif d’avoir une présence sur le web
                                que ce soit pour partager une passion, communiquer, réaliser un projet ou créer sa boutique en ligne. Après une carrière commerciale
                                de quinze ans avec le client au centre de sa démarche, Julien a décidé de poursuivre cette vision dans sa nouvelle activité.
                                Nous mettons tout en oeuvre pour vous procurer cette nouvelle fenêtre ouverte sur le monde que représente un site web
                            </p>
                        </div>
                    </div>
                </section>
                <section
                    style={{
                        backgroundImage: `url(${ikigai_background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}>
                        <div className="responsive-center p-4 lg:p-0 xl:py-4 text-main-dark">
                            <div className="w-full bg-main-white-opacity shadow-xl rounded-xl lg:m-4 xl:m-8 min-h-350 lg:p-6 lg:w-1/2">
                                <h3 className="text-2xl md:text-3xl pt-4 lg:pt-0 mt-6 lg:text-4xl">e-kigaï-dev: Pourquoi ?</h3>
                                <p className="text-xl mt-5 md:mt-8 px-2 lg:px-0 leading-10 lg:text-2xl lg:leading-10">
                                    L’ikigaï est une philosophie de vie japonaise qui peut se traduire par ‘’joie de vivre’’ et ‘’raison d’être’’.
                                    Elle réside dans le fait de trouver un sens à notre vie, une raison de se lever et d’être heureux chaque jour.
                                    Nous pouvons résumer cette philosophie ainsi: Allier passion et travail, trouver ce pourquoi on est doué et qui nous passionne.
                                    C’est tout naturellement qu’à la création de l’entreprise nous nous devions d’inclure ce terme tant il nous représente et fait écho en nous
                                </p>
                            </div>
                            <div className="w-full lg:w-1/2 mt-5 lg:mt-0 shadow-xl rounded-xl lg:min-h-350 lg:m-8 xl:my-auto bg-main-white-opacity">
                                <img className="w-2/3 py-12 mx-auto" src={definition} alt="définition  de l'ikigaï" />
                            </div>
                        </div>
                </section>
                <section>
                    <div className="py-4 px-4 md:py-8 lg:py-12">
                        <h3 className="text-center py-4 lg:py-8 text-main-blue text-2xl md:text-5xl">e-kigaï-dev: Notre philosophie…</h3>
                        <div
                            style={{
                                backgroundImage: `url(${philo_background})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                            className="w-full p-6 lg:5/6 xl:w-2/3 mx-auto lg:p-12 rounded-xl">

                            <div className="w-full md:w-2/3 shadow-xl xl:my-12 mx-auto rounded-xl bg-main-white">
                                <p className="text-main-dark p-2 lg:p-12 md:text-xl lg:text-2xl md:leading-10">
                                    Notre philosophie est simple: Appliquer notre vision commerciale en positionnant notre client au centre de notre démarche.
                                    Créer une relation basée sur l’écoute, l’échange et l’accompagnement. Notre énergie, nos compétences et notre philosophie sont à votre service
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-main-blue text-main-white">
                    <div className="py-4 md:py-0 lg:py-8 xl:py-12">
                        <AdviceCarousel />
                    </div>
                </section>
            </>
        );
    }
}

export default AboutPage;