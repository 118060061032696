import React, {Component} from 'react';
import Header from "../../components/Header/Header";

import vitrine from '../../medias/images/vitrine.svg';
import shop from '../../medias/images/shop.svg';
import family from '../../medias/images/family.svg';
import association from '../../medias/images/famille.svg';
import VitrineCarousel from "../../components/Carousel/VitrineCarousel";
import QuoteSection from "../../components/Sections/QuoteSection";

class VitrinePage extends Component {

    isMobile = window.innerWidth <= 768;
    componentDidMount() {
        if(this.isMobile){
            window.scrollTo(0,350);
        }else{
            window.scrollTo(0,650);
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="text-main-dark p-4 lg:p-12">
                    <div className="py-4 lg:py-8">
                        <h2 className="text-center text-2xl md:text-5xl">Réalisation de sites vitrines</h2>
                    </div>
                    <div className="block xl:flex xl:justify-center xl:items-center py-4 lg:py-8">
                        <div className="w-full xl:w-2/5">
                            <h3 className="text-main-blue text-2xl md:text-4xl px-4 py-4 md:py-8 font-bold">Un site vitrine, c'est quoi ?</h3>
                            <p className="text-lg md:text-xl text-center xl:text-right md:leading-10 leading-10">
                                C'est un site web et web mobile permettant de gagner en visibilité sur internet et ainsi présenter
                                les services ou les produits d’une organisation, il peut être comparé à la devanture ou la vitrine d'un magasin.
                                Il doit refléter votre savoir-faire, votre sérieux, la pertinence de vos solutions et offrir une expérience
                                utilisateur de qualité. Ce type de site est dit statique, c'est à dire qu'il n'offre pas d'interaction à l'utilisateur
                                ou très peu, des liens, ou un formulaire de contact par exemple. C'est un outil de communication qui aujourd'hui est
                                essentiel pour toute entreprise ou organisation
                            </p>
                        </div>
                        <div className="w-full xl:w-1/5">
                            <img className="object-cover w-full" src={vitrine} alt="sites vitrines" />
                        </div>
                        <div className="w-full xl:w-2/5">
                            <h3 className="text-main-blue text-2xl md:text-4xl px-4 py-4 md:py-8 font-bold">Les plus de nos sites vitrines</h3>
                            <p className="text-lg md:text-xl text-center xl:text-left leading-10 md:leading-10">
                                Notre solution vous permet de garder la main sur votre communication et la manière dont sera perçue votre entreprise ou organisation sur internet.
                                La démarche qui est la nôtre vous garantit un réél outil au quotidien, de gagner en professionnalisme, et vous démarquer d'une page Facebook.
                                Notre expertise nous permet de mettre votre contenu en valeur et vous permettre de transmettre votre message de façon claire.
                                Les compétences techniques qui sont les nôtres vous procurent un projet avec une forte maintenabilité, c'est à dire durable malgré l'évolution des technologies
                            </p>
                        </div>
                    </div>
                </section>
                <section className="bg-main-blue text-main-white">
                    <div className="p-4 lg:p-12">
                        <div className="py-4 xl:py-8">
                            <h2 className="text-center text-2xl md:text-5xl">Un site vitrine, c'est pour qui ?</h2>
                        </div>
                        <div className="responsive-center">
                            <div className="w-full lg:w-1/3 mt-5 lg:min-h-500 lg:mt-0">
                                <img className="w-1/2 lg:h-32 xl:h-60 mx-auto" src={shop} alt="commerçants" />
                                <h3 className="text-xl py-4 md:text-2xl">Commercants</h3>
                                <p className="px-6 text-lg">
                                    Un site vitrine permet à un commerçant ou artisan de communiquer sur ses produits, ses services et de véhiculer son image de marque. Il permet aussi
                                    de bénéficier d'un formulaire de contact permettant de toucher de nouveaux clients
                                </p>
                            </div>
                            <div className="w-full lg:w-1/3 mt-5 lg:min-h-500 lg:mt-0">
                                <img className="w-1/2 lg:h-32 xl:h-60 mx-auto" src={association} alt="associations" />
                                <h3 className="text-xl py-4 md:text-2xl">Associations</h3>
                                <p className="px-6 text-lg">
                                    Un site vitrine permet à une association de communiquer sur ses valeurs, ses objectifs, ses événements et sur le bien fondé de sa démarche. Il permet aussi
                                    de bénéficier d'un formulaire de contact permettant d'étendre son réseau
                                </p>
                            </div>
                            <div className="w-full lg:w-1/3 lg:min-h-500 mt-5 lg:mt-0">
                                <img className="w-1/2 lg:h-32 xl:h-60 mx-auto " src={family} alt="particuliers" />
                                <h3 className="text-xl py-4 md:text-2xl">Particuliers</h3>
                                <p className="px-6 text-lg">
                                    Un site vitrine permet à un particulier de partager une passion, un événement. Cela peut prendre différentes formes allant du blog, à l'application personnalisée.
                                    Il permet aussi de bénéficier d'un formulaire de contact permettant de garder le lien avec sa communauté
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="p-12">
                        <div className="py-4 lg:py-8">
                            <h2 className="text-center text-main-blue text-2xl md:text-5xl">Nos réalisations</h2>
                        </div>
                        <VitrineCarousel />
                    </div>
                </section>
                <QuoteSection />
            </>
        );
    }
}

export default VitrinePage;