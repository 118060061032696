import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";


import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import LoaderPage from "./pages/LoaderPage/LoaderPage";
import VitrinePage from "./pages/VitrinePage/VitrinePage";
import EcommercePage from "./pages/EcommercePage/EcommercePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import QuotePage from "./pages/QuotePage/QuotePage";
import PolicyPage from "./pages/PolicyPage/PolicyPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import HomePage from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Footer from "./components/Footer/Footer";


class App extends Component {

    state = {
        loading: true
    };

    componentDidMount() {
        // this simulates an async action, after which the component will render the content
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }

    demoAsyncCall =() => {
        return new Promise((resolve) => setTimeout(() => resolve(), 0));
    }

    render() {
        const { loading } = this.state;



        if(loading) { // if your component doesn't have to wait for an async action, remove this block
            return <LoaderPage/>; // render null when app is not ready
        }

        return (
            <Router>
                <div className="App">
                    <Switch>
                        <Route exact path="/sites-vitrines" component={VitrinePage} />
                        <Route exact path="/sites-e-commerces" component={EcommercePage} />
                        <Route exact path="/nos-services" component={ServicePage} />
                        <Route exact path="/devis" component={QuotePage} />
                        <Route exact path="/notre-histoire" component={AboutPage} />
                        <Route exact path="/contact" component={ContactPage} />
                        <Route exact path="/mentions-legales" component={PolicyPage} />
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="*" component={NotFoundPage} />
                    </Switch>
                    <Footer />
                </div>
                <ToastContainer
                    className="toast-container-app"
                    position={toast.POSITION.BOTTOM_RIGHT}
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Router>
        );
    }
}

export default App;