import React from 'react';
import Navbar from "../Navbar/Navbar";
import Hero from "../Hero/Hero";

const Header = () => {
    return (
        <>
            <Hero />
            <Navbar />
        </>
    );
};

export default Header;
