import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {toast} from "react-toastify";
import{ init } from 'emailjs-com';
import {QUOTE_TEMPLATE_ID, MAIL_CONTACT_KEY, MAIL_SERVICE_ID} from "../../config";
import * as emailjs from "emailjs-com";

init(MAIL_CONTACT_KEY);

const QuoteForm = ({history}) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [type, setType] = useState("");
    const [design, setDesign] = useState("");
    const [content, setContent] = useState("");
    const [redirect, setRedirect] = useState(false);

    if(redirect){
        return <Redirect to="/" push />
    }

    const isPhone = () =>{
        let reg = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;

        if(phone.match(reg)){
            return true;
        }else {
            toast.error("Problème dans le format de votre téléphone");
            return false;
        }
    };

    const isEmail = () =>{
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if(email.match(regex)){
            return true;
        }else{
            toast.error("Problème dans le format de votre email");
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(name && company && isPhone() && type && content && isEmail()){
            sendFeedback(QUOTE_TEMPLATE_ID, {
                name,
                company,
                email,
                phone,
                type,
                design,
                content,
            });
        }else {
            toast.error("Tous les champs du formulaire sont obligatoires");
        }
    };

    const sendFeedback = (templateId, variables) => {

        emailjs
            .send(MAIL_SERVICE_ID, templateId, variables)
            .then((res) => {
                toast.info("Message envoyé")
                setName("");
                setCompany("");
                setPhone("");
                setDesign("");
                setType("");
                setEmail("");
                setContent("");
                setRedirect(true);
            })
            .catch(
                (err) =>
                    toast.error("Une erreur est survenue lors de l'envoi"));
    };

    return (
        <>
            <div className="bg-main-blue py-8 md:py-12 lg:py-16">
                <form>
                    <div className="block md:flex md:justify-center md:items-center w-full lg:w-3/4 mx-auto">
                        <div className="w-full px-6 lg:px-0 flex flex-col h-350 md:w-1/2 lg:mx-8">
                            <div className="my-auto">
                                <input value={name} onChange={(e)=> setName(e.target.value)}  placeholder="Votre nom..." className="w-full bg-gray-300 h-14 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" name="name"/>
                            </div>
                            <div className="my-auto">
                                <input value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Votre email..." className="w-full bg-gray-300 h-14 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" type="email" name="email" />
                            </div>
                            <div className="my-auto">
                                <input value={company} onChange={(e)=> setCompany(e.target.value)} placeholder="Nom de votre société..." className="w-full h-14 bg-gray-300 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" type="text" name="company"  />
                            </div>
                            <div className="my-auto">
                                <input value={phone} onChange={(e)=> setPhone(e.target.value)} placeholder="Votre téléphone..." className="w-full bg-gray-300 h-14 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" type="tel" name="phone"  />
                            </div>
                        </div>
                        <div className="w-full px-6 lg:px-0 flex flex-col h-350 md:w-1/2 lg:mx-8">
                            <div className="my-auto">
                                <select value={type} onChange={(e)=> setType(e.target.value)} className="w-full bg-gray-300 h-14 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" name="type">
                                    <option value="type de projet inconnu">--Type de besoin--</option>
                                    <option value="Inconnu">Non défini</option>
                                    <option value="Site vitrine">Site vitrine</option>
                                    <option value="Site e-commerce">Site e-commerce</option>
                                    <option value="Formation">Formation</option>
                                </select>
                            </div>
                            <div className="my-auto">
                                <select value={design} onChange={(e)=> setDesign(e.target.value)} className="w-full h-14 bg-gray-300 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" name="design">
                                    <option value="design à définir">Pas de design</option>
                                    <option value="maquette prête">Maquette réalisée</option>
                                    <option value="maquette à réaliser">Maquette à réaliser</option>
                                </select>
                            </div>
                            <div className="my-auto">
                                <textarea value={content} onChange={(e)=> setContent(e.target.value)} placeholder="Votre message..." className="w-full bg-gray-300 text-main-dark my-auto p-3 rounded-lg focus:outline-none focus:shadow-outline" rows={5} name="message">

                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 lg:w-1/4 md:py-4 lg:py-8 mx-auto">
                        <button onClick={handleSubmit} className="uppercase text-sm font-bold tracking-wide bg-main-blue border-2 border-white text-main-white hover:text-main-blue hover:bg-white hover:border-main-blue transition-all duration-1000 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline" type="submit">ENVOYER</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default QuoteForm;
