import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import pizzeria_shop from '../../medias/logo/logo-pizzav2.svg';
import euratech_kids_shop from '../../medias/logo/commerce-demo.svg';
import bijouterie_shop from '../../medias/logo/bijoux.svg';

const EcommerceCarousel = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                transitionDuration={750}
                containerClass="carousel-container min-h-250 md:min-h-350"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="bottom-"
                itemClass="carousel-item-padding-40-px"
            >
                <div className="center">
                    <a href="https://v2-pizza.xn--e-kiga-dev-c9a.fr/" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site de pizzeria en ligne</p>
                        <img className="h-32 mx-auto" src={pizzeria_shop} alt="site de pizzeria avec commande en ligne" />
                    </a>
                </div>
                <div className="center">
                    <a href="https://euratech-demo.xn--e-kiga-dev-c9a.fr/" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site de reservation de formation</p>
                        <img className="h-32 mx-auto" src={euratech_kids_shop} alt="site permettant de reserver des places pour des cours" />
                    </a>
                </div>
                <div className="center">
                    <a href="https://bijouterie-demo.xn--e-kiga-dev-c9a.fr/" rel="noreferrer noopener" target="_blank">
                        <p className="text-center text-main-dark py-2 text-xl md:text-2xl">Site de bijouterie en ligne</p>
                        <img className="h-32 rounded-full mx-auto" src={bijouterie_shop} alt="site de vente de bijoux" />
                    </a>
                </div>
            </Carousel>
        </>
    );
};

export default EcommerceCarousel;
